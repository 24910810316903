// var graceRequest = require('@/components/js/request.js');
export default {
    namespaced: true,
    state: {
        currentLang: localStorage.getItem('currentLang'),
		// en_id:'1807613078465048834',  //精裕五金
		en_id:'1486272257314656256', //紫慧云
		// en_id:'1812735891240857982', //万嘉纸业
		// en_id:'1529000672463294464', //本地
		// @ts-ignore
		webcontents: localStorage.getItem('webcontents') ? JSON.parse(localStorage.getItem('webcontents')):'',
		current:''
    },
    mutations: {
        SET_LANG: (state, lang) => {
            state.currentLang = lang;
			localStorage.setItem('currentLang', lang);
        },
		SET_WEB:(state, webcontent)=>{
			const parsed = JSON.stringify(webcontent);
			localStorage.setItem('webcontents', parsed);
			state.webcontents = webcontent
		},
		SET_CURRENT:(state, current)=>{
			state.current = current;
			// localStorage.setItem('current', current);
		},
		SET_EN_ID:(state, en_id)=>{
			state.en_id = en_id;
			// localStorage.setItem('current', current);
		}
    },
    actions: {}
}
